<template>
  <validation-observer ref="form">
    <error-alert :error="error" />
    <slide-y-up-transition>
      <b-row
        class="discount mt-3"
      >
        <b-col
          md="6"
          sm="12"
        >
          <ok-text-input
            v-model="form.name"
            :is-required="true"
            :label="$t('discounts.discount-title')"
          />
        </b-col>
        <b-col
          md="6"
          sm="12"
        >
          <ok-text-input
            v-model="form.discount_value"
            :label="$t('coupons.discount-value')"
            :rules="'positive|min_value:0.01|max_value:100'"
            :show-append="true"
            append="%"
            is-required
          />
        </b-col>
        <b-col
          md="6"
          sm="12"
        >
          <ok-date-time-picker
            v-model="form.valid_from"
            :label="$t('coupons.start')"
            :min-date="minFromDate"
            is-required
            type="datetime"
          />
        </b-col>

        <b-col
          md="6"
          sm="12"
        >
          <ok-date-time-picker
            v-model="form.valid_until"
            :label="$t('coupons.end')"
            :min-date="form.valid_from"
            is-required
            type="datetime"
          />
        </b-col>
        <b-col
          md="6"
          sm="12"
        >
          <h4 class="font-weight-bold my-2">
            {{ $t('discounts.applied-to') }}
          </h4>
          <b-form-radio-group
            v-model="appliedTo"
            :options="options"
            class="ml-3"
            text-field="text"
            value-field="value"
          />
        </b-col>
        <b-col
          class="mt-1"
          md="6"
          sm="12"
        >
          <ok-text-input
            v-if="appliedTo==='categories'"
            :label="$t('discounts.choose-category')"
            :name="$t('discounts.categories')"
            is-required
          >
            <categories-select
              v-model="form.categories"
              :multiple="true"
              :selected="categoriesInitSelected"
              fire-object
              @categories="tableItems=$event"
            />
          </ok-text-input>

          <ok-text-input
            v-if="appliedTo==='items'"
            :label="$t('discounts.choose-item')"
            :name="$t('discounts.items')"
            is-required
          >
            <items-select
              v-model="form.items"
              :multiple="true"
              :selected="itemsInitSelected"
              fire-object
              @items="tableItems=$event"
            />
          </ok-text-input>
        </b-col>
        <b-col
          v-if="tableItems.length"
          md="12"
        >
          <b-table
            :fields="fields"
            :items="tableItems"
            bordered
            borderless
            striped
          >
            <template #cell(name)="data">
              {{ getName(data.item) }}
            </template>
            <template #cell(action)="data">
              <b-button
                size="sm"
                variant="danger"
                @click="removeItem(data.item.id)"
              >
                {{ $t('forms.delete') }}
              </b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </slide-y-up-transition>
  </validation-observer>

</template>

<script>
import {
  BButton, BCol, BFormRadioGroup, BRow, BTable,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import OkDateTimePicker from '@/@bya3/components/inputs/OkDateTimePicker'
import OkTextInput from '@/@bya3/components/inputs/OkTextInput'
import ErrorAlert from '@/@bya3/components/alerts/ErrorAlert'
import CategorySelect from '@/@bya3/components/inputs/CategorySelect'
import LocalizationService from '@/services/localization-service'
import DiscountsApi from '@/api/discounts-api'
import ItemsSelect from '@/views/store/products/components/ItemsSelect'
import ErrorHandler from '@/utils/error-handler'
import dayjs from 'dayjs'

export default {
  name: 'DiscountForm',
  components: {
    ErrorAlert,
    OkTextInput,
    OkDateTimePicker,
    BRow,
    BCol,
    BTable,
    BButton,
    ValidationObserver,
    BFormRadioGroup,
    'items-select': ItemsSelect,
    'categories-select': CategorySelect,
  },
  props: {
    discount: {
      type: Object,
      default: () => {
      },
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      error: null,
      form: {},
      options: [],
      appliedTo: 'all_menu',
      itemsInitSelected: [],
      categoriesInitSelected: [],
      minDate: dayjs().add(10, 'm').format('YYYY-MM-DD HH:mm'),
      minFromDate: dayjs().add(10, 'm').format('YYYY-MM-DD HH:mm'),
      fields: [
        { key: 'name', label: this.$t('fields.name') },
        { key: 'action', label: this.$t('forms.actions') },
      ],
      tableItems: [],
    }
  },
  computed: {
    currency() {
      return this.$store.getters['auth/currency'] ?? ''
    },
    isEdit() {
      return !!this.$route?.params?.id
    },
  },
  watch: {
    discount() {
      this.init()
    },
  },
  mounted() {
    this.init()
    this.options = [
      { text: this.$t('discounts.all-menu'), value: 'all_menu' },
      { text: this.$t('discounts.specific-categories'), value: 'categories' },
      { text: this.$t('discounts.specific-items'), value: 'items' },
    ]
  },
  methods: {
    getName(item) {
      if (item?.name) return item.name
      return LocalizationService.getTranslate(item, 'name', this.selectedLocale)
    },
    removeItem(id) {
      let index = -1
      if (this.appliedTo === 'items') {
        index = this.form.items.findIndex(item => item === id)
        if (index > -1) this.form.items.splice(index, 1)
      } else {
        index = this.form.categories.findIndex(item => item === id)
        if (index > -1) this.form.categories.splice(index, 1)
      }
    },
    init() {
      this.form = this.edit ? this.discount : this.getInitialForm()
      if (this.form?.id) {
        if (this.form.all_menu) this.appliedTo = 'all_menu'
        if (this.form?.items?.length) this.appliedTo = 'items'
        if (this.form?.categories?.length) this.appliedTo = 'categories'
        this.discount.items.forEach(item => {
          this.itemsInitSelected.push(item)
        })
        this.discount.categories.forEach(category => {
          this.categoriesInitSelected.push(category)
        })
        this.form.items = this.discount.items.map(i => i.id)
        this.form.categories = this.discount.categories.map(i => i.id)
        this.minFromDate = this.form.valid_from
      }
    },
    getInitialForm() {
      return {
        name: '',
        valid_until: '',
        valid_from: this.minDate,
        discount_value: 0,
        discount_type: '0',
        all_menu: false,
        categories: [],
        items: [],
      }
    },
    async save() {
      this.error = null
      const valid = await (this.$refs.form.validateWithInfo())
      if (!valid.isValid) {
        this.$emit('error')
        this.error = {
          title: this.$tc('errors.field-invalid', 2),
          message: ErrorHandler.getValidationErrors(valid.errors),
        }
        return
      }
      const form = { ...this.form }
      form.all_menu = this.appliedTo === 'all_menu' ? 1 : 0
      if (this.appliedTo === 'all_menu') {
        form.categories = []
        form.items = []
      } else if (this.appliedTo === 'categories') {
        form.items = []
      } else if (this.appliedTo === 'items') {
        form.categories = []
      }
      if (this.form?.id) form.id = this.form.id
      await DiscountsApi.process(form)
        .then(() => {
          this.$emit('processed')
          this.$notifySuccess(this.$t(`discounts.${this.edit ? 'update' : 'add'}-success`))
          this.$router.push({ name: 'owner:discounts' })
        })
        .catch(e => {
          this.error = e
          this.$emit('error')
          throw Error('Failed')
        })
    },
  },
}
</script>
