<template>
  <ok-modal
    :value="value"
    size="sm"
    dialog-class="list-modal"
    :title="title"
    @change="loading ? null : $emit('input', $event)"
  >
    <ul>
      <li
        v-for="item in list"
        :key="item.id"
      >
        {{ getTranslate(item.locales) }}
      </li>
    </ul>
    <template #actions>
      <div class="w-100">
        <b-button
          variant="dark"
          class="float-right"
          @click="$emit('close')"
        >
          {{ $t('forms.done') }}
        </b-button>
      </div>
    </template>
  </ok-modal>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import OkModal from '@/@bya3/components/modals/OkModal'
import LocalizationService from '@/services/localization-service'

export default {
  name: 'ListModal',
  components: { OkModal, BButton },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
  },
  methods: {
    getTranslate(locales) {
      return LocalizationService.getTranslate(locales)
    },
  },
}
</script>
