import OkTableConfigType from '@/@bya3/components/tables/ok-table/OkTableConfigType'
import { API_URL } from '@/constants/config'
import CommonTableActions from '@/constants/common-table-actions'

const DiscountsTableConfig: OkTableConfigType = {
  mode: 'remote',
  url: `${API_URL}/discounts`,
  rowsPath: 'discounts',
  rowClickable: true,
  columns: [
    {
      label: 'discounts.discount-title',
      field: 'name',
    },
    {
      label: 'coupons.discount',
      field: 'discount',
    },
    {
      label: 'discounts.applied-to',
      field: 'applied_to',
    },
    {
      label: 'coupons.start',
      field: 'valid_from_formatted',
    },
    {
      label: 'coupons.end',
      field: 'valid_until_formatted',
    },
  ],
  paginationOptions: {
    enabled: true,
  },
  sortOptions: {
    enabled: false,
  },
  actions: [
    ...CommonTableActions,
  ],
  filters: [
    {
      label: 'discounts.discount-title',
      query: 'name',
      colLength: 3,
    },
    {
      label: 'coupons.discount',
      query: 'discount',
      colLength: 3,
    },
    {
      label: 'coupons.start',
      query: 'valid_from',
      type: 'date',
      colLength: 3,
    },
    {
      label: 'coupons.end',
      query: 'valid_until',
      type: 'date',
      colLength: 3,
    },
    {
      label: 'discounts.categories',
      query: 'categories',
      type: 'autocomplete',
      colLength: 3,
      completeType: 'category',
      multiple: true,
    },
    {
      label: 'discounts.items',
      query: 'items',
      type: 'autocomplete',
      colLength: 3,
      completeType: 'item',
      multiple: true,
    },
  ],
}

export default DiscountsTableConfig
