<template>
  <empty-card
    :title="$t(editMode?'discounts.edit-discount':'discounts.add-discount')"
  >
    <discount-form
      ref="discountForm"
      :discount="discount"
      :edit="editMode"
      @error="loading=false"
      @processed="$emit('processed')"
    />
    <template #title>
      <div class="d-flex">
        <b-button
          variant="dark"
          class="mx-1"
          :disabled="loading"
          @click="submit()"
        >
          <b-spinner
            v-if="loading"
            class="mx-1"
            small
            label="submitting..."
          />
          {{ $t('forms.save') }}
        </b-button>
        <b-button
          variant="outline-dark"
          @click="$router.push({name:'owner:discounts'})"
        >
          {{ $t('forms.cancel') }}

        </b-button>
      </div>
    </template>
  </empty-card>
</template>

<script>
import { BButton, BSpinner } from 'bootstrap-vue'
import EmptyCard from '@/@bya3/components/cards/EmptyCard'
import DiscountsApi from '@/api/discounts-api'
import DiscountForm from './components/DiscountForm'

export default {
  name: 'ProcessDiscountPage',
  components: {
    EmptyCard,
    DiscountForm,
    BButton,
    BSpinner,
  },
  data() {
    return {
      loading: false,
      discount: {},
    }
  },
  computed: {
    editMode() {
      return !!this.$route?.params?.id
    },
  },
  mounted() {
    this.fetchDiscount()
  },
  methods: {
    async submit() {
      this.loading = true
      await this.$refs.discountForm.save()
      this.loading = false
    },
    async fetchDiscount() {
      if (!this.editMode) return
      this.loading = true
      DiscountsApi.getById(this.$route.params.id)
        .then(res => { this.discount = res })
        .catch(e => this.$swalError(e))
        .finally(() => { this.loading = false })
    },
  },

}
</script>
