<template>
  <div>
    <ok-table
      ref="datatable"
      :title="$t('discounts.title')"
      :config="config"
      @edit="$router.push({name:'owner:discounts-process',params:{id:$event.row.id}})"
      @delete="handleDelete"
    >
      <template slot="buttons">
        <b-button
          size="md"
          variant="dark"
          class="mx-md-1"
          @click="$router.push({name:'owner:discounts-process'})"
        >
          {{ $t('discounts.add-discount') }}
        </b-button>
      </template>
      <template
        slot="discount-column"
        slot-scope="{props}"
      >
        <b-badge variant="info">
          <span v-if="props.row.discount_type==='fixed'">
            {{ `${props.row.discount_value} ${currency}` }}
          </span>
          <span v-else>
            {{ `${props.row.discount}` }}
          </span>
        </b-badge>
      </template>
      <template
        slot="applied_to-column"
        slot-scope="{props}"
      >
        <span
          class="cursor-pointer text-underline"
          @click="openListModal(props.row)"
        >{{ getType(props.row) }}</span>

      </template>
    </ok-table>
    <list-modal
      v-model="listModal"
      :list="list"
      :title="listTitle"
      @close="listModal=false"
    />
  </div>
</template>

<script>
import { BButton, BBadge } from 'bootstrap-vue'
import DiscountsApi from '@/api/discounts-api'
import DiscountsTableConfig from './config/discounts-table-config'
import ListModal from './components/ListModal'

export default {
  components: {
    ListModal,
    BButton,
    BBadge,
    OkTable: () => import(/* webpackChunkName: "ok-table" */ '@/@bya3/components/tables/ok-table/OkTable'),
  },
  data() {
    return {
      config: DiscountsTableConfig,
      listModal: false,
      list: [],
      listTitle: '',
    }
  },
  computed: {
    currency() {
      return this.$store.getters['auth/currency'] ?? ''
    },
  },
  methods: {
    refreshTable() {
      this.$refs.datatable.loadItems()
    },
    openListModal(row) {
      if (row.all_menu) return
      if (row.items?.length) {
        this.list = row.items
        this.listTitle = this.$t('discounts.items')
      }
      if (row.categories?.length) {
        this.list = row.categories
        this.listTitle = this.$t('discounts.categories')
      }
      this.listModal = true
    },
    getType(row) {
      if (row.all_menu) return this.$t('discounts.all-menu')
      if (row.items?.length) return `${row.items.length} ${this.$t('discounts.items')}`
      if (row.categories?.length) return `${row.categories.length} ${this.$t('discounts.categories')}`
      return '-'
    },
    handleDelete(data) {
      this.$okConfirm(this.$t('general.delete-confirm'), {
        preConfirm: () => DiscountsApi.delete(data.row.id)
          .then(response => {
            this.$notifySuccess(response.message)
            this.refreshTable()
          })
          .catch(e => this.$notifyError(e)),
      })
    },
  },
}
</script>
