import $http from '@/libs/axios'

class DiscountsApi {
  static async delete(id: number) {
    return (await $http.delete(`discounts/${id}`)).data
  }

  static async list() {
    return (await $http.get('discounts')).data
  }

  static async process(data: any) {
    return (await $http.post('discounts', data)).data
  }

  static async getById(id: string) {
    return (await $http.get(`discounts/${id}`)).data
  }
}

export default DiscountsApi
